import './Header.css';
import Logo from '../assets/media/logo.png';
import TG from '../assets/media/tg.png';
import X from '../assets/media/x.png';

function Header() {
    return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <nav class="navbar d-lg-block d-none navbar-expand-lg navbar-light bg-pink rounded">
                            <div class="container-fluid">
                                <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>

                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav w-75 justify-content-between ms-auto my-0 mb-lg-0">
                                        <li class="nav-item">
                                            <a class="nav-link active" aria-current="page" href="#banner">About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#story">Story</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#ppnomics">Ppnomics</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#nft">NFT</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#roadmap">Roadmap</a>
                                        </li>
                                    </ul>

                                    <ul class="navbar-nav social ms-auto d-flex flex-row">
                                        <li class="nav-item me-2">
                                            <a class="nav-link" href="https://t.me/PinkPiggy_Portal" target='_link'><img src={TG} alt="Telegram" /></a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="http://twitter.com/pinkpiggysol" target='_link'><img src={X} alt="X" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                        {/* Mobile Navbar (Hidden on Desktop) */}

                        <nav class="navbar navbar-light bg-pink rounded d-block d-lg-none">
                            <div class="container-fluid">
                                <a class="navbar-brand" href="/"><img src={Logo} alt="Mobile Logo" /></a>
                                <div class="social d-flex flex-row ms-auto">
                                    <a class="nav-link me-3" href="https://t.me/PinkPiggy_Portal" target='_link'><img src={TG} alt="Telegram" /></a>
                                    <a class="nav-link" href="http://twitter.com/pinkpiggysol" target='_link'><img src={X} alt="X" /></a>
                                </div>
                            </div>
                        </nav>

                    </div>
                </div>
            </header>
        </>

    );
}

export default Header;