import './Home.css'
import { useState, useEffect } from 'react'
import Logo from '../assets/media/logo.png'
import Banner from '../assets/media/banner-img.png'
import Story from '../assets/media/story.png'
import PPNomics from '../assets/media/ppnomics.gif'
import NFT from '../assets/media/nft.gif'
import Epic from '../assets/media/epic.png'
import Rare from '../assets/media/rare.png'
import Myth from '../assets/media/myth.png'
import Common from '../assets/media/common.png'
import Staking from '../assets/media/staking.png'
import Roadmap from '../assets/media/roadmap.gif'
import snowflakeImage from '../assets/media/snow-1.png'
import Featured1 from '../assets/media/featured-1.png'
import Featured2 from '../assets/media/featured-2.png'
import Featured3 from '../assets/media/featured-3.png'
import Featured4 from '../assets/media/featured-4.png'
import Featured5 from '../assets/media/featured-5.png'
import * as solanaWeb3 from '@solana/web3.js'

import Snowfall from 'react-snowfall'

function Home() {
    const [presaleCopied, setPresaleCopied] = useState(false)
    const [currentRaise, setCurrentRaise] = useState('0')
    const handleCopyPresale = () => {
        const presaleAddressInput = document.getElementById('presale-address')
        presaleAddressInput.select()
        document.execCommand('copy')
        setPresaleCopied(true)

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setPresaleCopied(false)
        }, 2000)
    }
    const fetchSolToUsdRate = async () => {
        // Placeholder for fetching SOL to USD rate
        // This should be replaced with an actual API call to a service like CoinGecko, Binance, etc.
        // For example, using CoinGecko API:
        const response = await fetch(
            'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
        )
        const data = await response.json()
        return data.solana.usd
    }
    const fetchSolBalance = async () => {
        const connection = new solanaWeb3.Connection(
            'https://newest-withered-hill.solana-mainnet.quiknode.pro/16e2515775325add062b867da300c467c9e601e0/'
        )
        const address = new solanaWeb3.PublicKey(
            'E2mNNzKTgbv1rGt2NJYM3pQrNk2GEjKRSbGEFPa3KdbH'
        )

        try {
            const balanceInLamports = await connection.getBalance(address)
            console.log('balanceInLamports', balanceInLamports)

            const balanceInSol = balanceInLamports / solanaWeb3.LAMPORTS_PER_SOL

            const solToUsdRate = await fetchSolToUsdRate()
            const balanceInUsd = (balanceInSol * solToUsdRate).toFixed(2)

            console.log('Balance in USD:', balanceInUsd)
            setCurrentRaise(' $ ' + balanceInUsd)
        } catch (error) {
            console.error('Error fetching balance:', error)
            setCurrentRaise('Error')
        }
    }
    useEffect(() => {
        fetchSolBalance()
        const intervalId = setInterval(fetchSolBalance, 60000) // Fetch every 10 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId)
    }, [])
    const [tokenCopied, setTokenCopied] = useState(false)
    const handleCopyToken = () => {
        const tokenAddressInput = document.getElementById('token-address')
        tokenAddressInput.select()
        document.execCommand('copy')
        setTokenCopied(true)

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setTokenCopied(false)
        }, 2000)
    }

    const Countdown = () => {
        const [targetDate, setTargetDate] = useState(
            new Date(Date.UTC(2024, 4, 27, 9, 0, 0))
        ) // JUNE (0 indexed) 05 2024, 15:00 UTC

        const [days, setDays] = useState(0)
        const [hours, setHours] = useState(0)
        const [minutes, setMinutes] = useState(0)
        const [seconds, setSeconds] = useState(0)

        useEffect(() => {
            const intervalId = setInterval(() => {
                const now = new Date()
                const remainingTime = targetDate - now

                const newDays = Math.floor(
                    remainingTime / (1000 * 60 * 60 * 24)
                )
                const newHours = Math.floor(
                    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                )
                const newMinutes = Math.floor(
                    (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
                )
                const newSeconds = Math.floor(
                    (remainingTime % (1000 * 60)) / 1000
                )

                setDays(newDays)
                setHours(newHours.toString().padStart(2, '0'))
                setMinutes(newMinutes.toString().padStart(2, '0'))
                setSeconds(newSeconds.toString().padStart(2, '0'))

                setDays(newDays.toString().padStart(2, '0')) // Two-digit padding
                setHours(newHours.toString().padStart(2, '0'))
                setMinutes(newMinutes.toString().padStart(2, '0'))
                setSeconds(newSeconds.toString().padStart(2, '0'))

                if (remainingTime <= 0) {
                    clearInterval(intervalId)
                    // Handle the timer reaching zero (optional)
                }
            }, 1000)

            return () => clearInterval(intervalId)
        }, [])

        return (
            <div className='countdown-container'>
                <div className='countdown-item days'>
                    <span className='value'>{days}</span>
                    <span className='label'>Days</span>
                </div>
                <div className='countdown-item hours'>
                    <span className='value'>{hours}</span>
                    <span className='label'>Hours</span>
                </div>
                <div className='countdown-item minutes'>
                    <span className='value'>{minutes}</span>
                    <span className='label'>Minutes</span>
                </div>
                <div className='countdown-item seconds'>
                    <span className='value'>{seconds}</span>
                    <span className='label'>Seconds</span>
                </div>
            </div>
        )
    }

    // Function to create snowflakes
    const snowflake1 = document.createElement('img')
    snowflake1.src = snowflakeImage

    const images = [snowflake1]

    //ends here

    const [isVisible, setIsVisible] = useState(false)

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    // Detect scroll position and show/hide button
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true)
            } else {
                setIsVisible(false)
            }
        }

        window.addEventListener('scroll', toggleVisibility)

        // Cleanup function to remove event listener on unmount
        return () => window.removeEventListener('scroll', toggleVisibility)
    }, [])

    return (
        <>
            <section id='banner'>
                <Snowfall
                    // Applied to the canvas element
                    style={{
                        background: 'transparent',
                        width: '100vw',
                        zIndex: '-1',
                    }}
                    // Size of icons
                    radius={[40.0, 87.0]}
                    // Controls the number of snowflakes that are created (default 150)
                    snowflakeCount={30}
                    // Pass in the images to be used
                    images={images}
                />
                {/* <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='intro'>
                                <div className='identity d-flex justify-content-md-start justify-content-center align-items-center flex-wrap mb-3'>
                                    <img
                                        src={Logo}
                                        className='me-3'
                                        alt='Logo'
                                    />
                                    <h1>PINK PIGGY</h1>
                                </div>
                                <img
                                    src={Banner}
                                    className='d-block d-md-none img-fluid mb-3'
                                    alt='banner'
                                />
                                <h4>SALE ENDS IN</h4>
                                <div className='countdown mt-2 mb-3'>
                                    <Countdown />
                                </div>

                                <div class='card mb-3'>
                                    <div class='card-body d-flex align-items-center'>
                                        <div class='w-100 info d-flex flex-wrap wrap-column justify-content-start align-items-center'>
                                            {' '}
                                            <h5 className='me-3'>
                                                PRESALE ADDRESS
                                            </h5>
                                            <p className='token'>
                                                E2mNNzKTgbv1rGt2NJYM3pQrNk2GEjKRSbGEFPa3KdbH
                                            </p>
                                        </div>

                                        <input
                                            id='presale-address'
                                            type='text'
                                            value='E2mNNzKTgbv1rGt2NJYM3pQrNk2GEjKRSbGEFPa3KdbH'
                                            readOnly
                                            style={{
                                                position: 'absolute',
                                                left: '-9999px',
                                            }}
                                        />

                                        <div class='input-group-append'>
                                            <button
                                                className='copy-btn'
                                                onClick={handleCopyPresale}
                                            >
                                                <i className='fa fa-copy'></i>
                                                <span
                                                    className={
                                                        presaleCopied
                                                            ? 'presale-tooltip-show'
                                                            : 'presale-tooltip-hide'
                                                    }
                                                >
                                                    Copied!
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='sol mt-3 d-flex justify-content-center justify-content-between align-items-center'>
                                    <div className='col-sm-6'>
                                        <h5 className='me-3 my-0 fw-bold'>
                                            CURRENT RAISE
                                        </h5>
                                    </div>
                                    <div className='col-sm-6 right d-flex justify-content-center align-items-center'>
                                        <h6 className='value text-white'>
                                            {currentRaise}
                                        </h6>
                                    </div>
                                </div>

                                <div className='home-list mt-3'>
                                    <ul>
                                        <li>
                                            Deposit SOL into your
                                            Phantom/Solflare wallet or any SOL
                                            wallet.
                                        </li>
                                        <li>
                                            Paste the presale address and
                                            transfer SOL.
                                        </li>
                                        <li>
                                            Presale maintains a fixed price for
                                            all contributors.
                                        </li>
                                        <li>
                                            Tokens will be airdropped to your
                                            SOL contribution wallet at launch.
                                        </li>
                                        <li>
                                            Presale sale allocation:
                                            5,000,000,000 PP
                                        </li>
                                        <li>Min Buy: 0.2 SOL, No Max Buy</li>
                                        <li>S/C: 200 SOL, H/C: 1,000 SOL</li>
                                    </ul>
                                </div>
                                <div className='home-cta d-flex flex-wrap justify-content-start align-items-center'>
                                    <a
                                        href='https://drive.google.com/file/d/1PuUm7n-1Z6kum-TUPvNE3kILzDo7oOna/view?usp=sharing'
                                        target='_blank'
                                    >
                                        <button>Whitepaper</button>
                                    </a>
                                    <a
                                        href='https://blocksafu.com/audit-solana/BNdqTDN3N8bS4YpaSkTyMfuSxZfta4cHKY7m5pPGf73c'
                                        target='_blank'
                                    >
                                        <button>Audit</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class='col-md-6'>
                            <div class='banner-img text-center d-none d-md-block'>
                                <img src={Banner} alt='banner' />
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 offset-md-3'>
                            <div className='intro text-center'>
                                <div className='identity d-flex justify-content-center align-items-center flex-wrap mb-3'>
                                    <img
                                        src={Logo}
                                        className='me-3'
                                        alt='Logo'
                                    />
                                    <h1>PINK PIGGY</h1>
                                </div>

                                {/* <div className='home-cta d-flex flex-wrap justify-content-center align-items-center'>
                                    <a
                                        href='https://drive.google.com/file/d/1PuUm7n-1Z6kum-TUPvNE3kILzDo7oOna/view?usp=sharing'
                                        target='_blank'
                                    >
                                        <button>Whitepaper</button>
                                    </a>
                                    <a
                                        href='https://blocksafu.com/audit-solana/BNdqTDN3N8bS4YpaSkTyMfuSxZfta4cHKY7m5pPGf73c'
                                        target='_blank'
                                    >
                                        <button>Audit</button>
                                    </a>
                                </div> */}

                                <img src={Banner} className='banner-img  my-3' alt='banner' />

                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section id='divider'></section>

            <section id='story'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2 className='text-center'>PINK PIGGY STORY</h2>
                        </div>
                        <div className='col-md-4 story-img'>
                            <img src={Story} alt='Story' className='' />
                        </div>
                        <div className='col-md-8 d-flex justify-content-center align-items-center'>
                            <div className='story-box'>
                                <p>
                                    For a while, Pink Piggy saved in a piggy
                                    bank but it didn't bring joy. Seeking
                                    change, Pink Piggy explored
                                    cryptocurrencies, initially hesitant but
                                    eventually diving in to improve finances and
                                    pursue dreams. Despite challenges, Pink
                                    Piggy found success, transforming life
                                    through cryptocurrency trading, gaining
                                    growth and joy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='divider'></section>

            <section id='ppnomics'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2 className='text-center'>PPNOMICS</h2>
                        </div>
                        <div className='col-md-6 mb-3 mb-md-0 text-center'>
                            <img
                                src={PPNomics}
                                alt='PPNomics'
                                className='pp-img'
                            />
                        </div>

                        <div className='col-md-6'>
                            <div className='info-box box-1 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label me-4'>PRESALE & LP</h6>
                                <h4 className='value'>80%</h4>
                            </div>
                            <div className='info-box box-2 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label me-4'>NFT STAKING</h6>
                                <h4 className='value'>10%</h4>
                            </div>
                            <div className='info-box box-3 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label me-4'>CEX/MARKETING</h6>
                                <h4 className='value'>10%</h4>
                            </div>
                        </div>
                    </div>
                    <div className='row row-2'>
                        <div className='col-md-4'>
                            <div className='info-box-alt box-4 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label'>
                                    Total Supply : 10,000,000,000
                                </h6>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='info-box-alt box-4 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label'>Symbol : PP</h6>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='info-box-alt box-4 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label'>Taxs : 0/0</h6>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='info-box-alt box-5 d-flex flex-wrap justify-content-center align-items-center'>
                                <h6 className='label mb-3 mb-md-0 me-0 me-md-2'>
                                    Token Address:{' '}
                                </h6>
                                <h6 className='token mb-3 mb-md-0 me-0 me-md-4'>
                                    BNdqTDN3N8bS4YpaSkTyMfuSxZfta4cHKY7m5pPGf73c
                                </h6>
                                <input
                                    id='token-address'
                                    type='text'
                                    value='BNdqTDN3N8bS4YpaSkTyMfuSxZfta4cHKY7m5pPGf73c'
                                    readOnly
                                    style={{
                                        position: 'absolute',
                                        left: '-9999px',
                                    }}
                                />
                                <button
                                    className='ms-3 copy-btn'
                                    onClick={handleCopyToken}
                                >
                                    <i className='fa fa-copy'></i>
                                    <span
                                        className={
                                            tokenCopied
                                                ? 'tooltip-show'
                                                : 'tooltip-hide'
                                        }
                                    >
                                        Copied!
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className='info-btn d-flex justify-content-center align-items-center flex-wrap'>
                            <button>LP WILL BE BURNED</button>
                            <button>OWNERSHIP WILL BE REOVOKED</button>
                        </div>
                    </div>
                </div>
            </section>

            <section id='divider'></section>

            <section id='nft'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2 className='text-center'>NFT UTILITY</h2>
                        </div>

                        <div className='col-md-10 order-2 order-md-1'>
                            <div className='para-box'>
                                <p>
                                    Our NFT platform will predominantly showcase
                                    NFT Staking, allowing holders to stake their
                                    NFTs and earn $PP rewards. Pink Piggy NFT
                                    comprises 800 NFTs, each with distinct
                                    characteristics dictating its rarity. Users
                                    can mint our NFTs and receive random
                                    rarities, each associated with a monthly
                                    percentage yield (MPY). Holding NFTs enables
                                    users to earn passive income in $PP tokens
                                </p>
                            </div>
                        </div>
                        <div className='col-md-2 nft-img order-1 order-md-2'>
                            <img src={NFT} alt='NFT' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className='nft-collection d-flex justify-content-center justify-content-md-start align-items-center flex-wrap'>
                                <img src={Common} alt='Common' />
                                <img src={Rare} alt='Rare' />
                                <img src={Epic} alt='Epic' />
                                <img src={Myth} alt='Myth' />
                            </div>
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                </div>
            </section>

            <section id='divider'></section>

            <section id='staking'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2 className='text-center'>STAKING</h2>
                        </div>
                        <div className='col-md-2 staking-img'>
                            <img src={Staking} alt='' />
                        </div>
                        <div className='col-md-10'>
                            <div className='para-box-2'>
                                <p>
                                    PP holders can stake their tokens via our
                                    platform to earn additional tokens.
                                    Additionally, NFT holders enjoy a higher APY
                                    of up to 6,000% for their staked tokens.{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='divider'></section>

            <section id='roadmap'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2 className='text-center'>ROADMAP</h2>
                        </div>
                        <div className='col-md-4 order-2 order-md-1'>
                            <div className='map'>
                                <h6>Chapter 1: Presale</h6>
                                <ul>
                                    <li>Establishment of PINK PIGGY</li>
                                    <li>Smart Contract Deployment</li>
                                    <li>Audit Procedure</li>
                                    <li>Presale Start</li>
                                </ul>
                            </div>
                            <div className='map'>
                                <h6>Chapter 2: DEX</h6>
                                <ul>
                                    <li>PP Launch on Raydium</li>
                                    <li>
                                        CMC/CG Fast Track Listing (CMC:
                                        CoinMarketCap, CG: CoinGecko)
                                    </li>
                                    <li>$10M Volume Daily</li>
                                    <li>NFT Mint & Staking</li>
                                    <li>Trendings & Ads Everywhere</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 order-1 order-md-2 d-flex justify-content-center align-items-center'>
                            <div className='roadmap-img'>
                                <img src={Roadmap} alt='Roadmap' />
                            </div>
                        </div>
                        <div className='col-md-4 order-3 order-md-3'>
                            <div className='map'>
                                <h6>Chapter 3: FAME</h6>
                                <ul>
                                    <li>$10M Market Cap</li>
                                    <li>Second Marketing Push</li>
                                    <li>Top Press Release</li>
                                    <li>20,000 Holders</li>
                                </ul>
                            </div>
                            <div className='map'>
                                <h6>Chapter 4: Top CEX</h6>
                                <ul>
                                    <li>
                                        Top CEXs Listings (CEX: Centralized
                                        Exchanges)
                                    </li>
                                    <li>$100M Market Cap</li>
                                    <li>Future Tradings On CEX</li>
                                    <li>40,000 Holders</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='divider'></section>

            <section id='featured'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2 className='text-center'>FEATURED IN</h2>
                        </div>
                        <div className='col-12'>
                            <div className='logos d-flex justify-content-center align-items-center flex-wrap wrap-row'>
                                <img
                                    src={Featured1}
                                    alt='Featured'
                                    className='featured-img'
                                />
                                <img
                                    src={Featured2}
                                    alt='Featured'
                                    className='featured-img'
                                />
                                <img
                                    src={Featured3}
                                    alt='Featured'
                                    className='featured-img'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mt-0 mt-md-3'>
                        <div className='col-12'>
                            <div className='logos d-flex justify-content-center align-items-center flex-wrap wrap-row'>
                                <img
                                    src={Featured4}
                                    alt='Featured'
                                    className='featured-img'
                                />
                                <img
                                    src={Featured5}
                                    alt='Featured'
                                    className='featured-img'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div
                className={`scroll-top ${isVisible ? 'show' : ''}`}
                onClick={scrollToTop}
            >
                <i className='fas fa-chevron-up'></i>
            </div>
        </>
    )
}

export default Home
