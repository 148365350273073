import './Footer.css';
import Logo from '../assets/media/logo.png';
import TG from '../assets/media/tg.png';
import X from '../assets/media/x.png';

function Footer() {
    return(
        <>

        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <div className="footer-logo d-flex justify-content-md-start justify-content-center align-items-center flex-wrap"><img src={Logo} className='me-3' alt="Logo" /><h1 className='text-white'>PINK PIGGY</h1></div>
                    </div>
                    <div className="col-md-6">
                        <div className="social d-flex justify-content-md-end justify-content-center align-items-center flex-wrap">
                            <a href="https://t.me/PinkPiggy_Portal" target='_link'><img src={TG} alt="Telegram" /></a>
                            <a href="http://twitter.com/pinkpiggysol" target='_link'><img src={X} alt="X" /></a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 text-center">
                        <p className="copyright text-white">Copyright © 2024. All Rights Reserved by PinkPiggy. <a href="mailto:info@pinkpiggy.wtf">info@pinkpiggy.wtf</a></p>
                    </div>
                </div>
            </div>
        </footer>
        
        </>

    );
}

export default Footer;